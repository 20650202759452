import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './LandingPage.css' // Existing styling
import logo from '../assets/wonderyarn-logo.png' // Logo
import mascot from '../assets/wonderyarn-mascot.png' // Mascot
import StoryInputModal from './StoryInputModal' // Modal component
import ChildStoryForm from './ChildStoryForm'
import { useTranslation } from 'react-i18next';

function LandingPage() {
  /*const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
*/
  const [showForm, setShowForm] = useState(false)
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className='landing-page'>
      <div className='stars'></div> {/* Stars background */}
      <div className='header'></div>
      <div className='content-with-mascot'>
        <div className='content'>
          <h1>{t('welcome')}</h1>
          {/* Conditionally hide the text and CTA button when the form is shown */}
          {!showForm && (
            <>
              <p>{t('description')}</p>
              <button className='cta-button' onClick={() => setShowForm(true)}>
                {t('free-story-cta')}
              </button>
            </>
          )}
          {showForm && <ChildStoryForm />}
          <p className='cta-subtext'>{t('no-registration')}</p>
        </div>

        <div className='mascot-container'>
          <img src={mascot} alt='WonderYarn Mascot' className='mascot' />
        </div>
      </div>
    </div>
  )
}

export default LandingPage
