import React from 'react'
import './AboutUs.css' // Custom CSS for About Us (same as Contact Us)
import mascot from '../assets/wonderyarn-mascot.png' // Mascot
import { useTranslation } from 'react-i18next';
function AboutUs() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className='about-us-page'>
      <div className='stars'></div> {/* Stars background */}
      <div className='content-with-mascot content-with-aboutus'>
        <div className='content'>
          <h1>About Us</h1>
          <p>{t('about-us-1')}</p>
          <p>{t('about-us-2')}</p>
          <p>{t('about-us-3')}</p>
          <p>{t('about-us-4')}</p>
          <p>{t('about-us-5')} ✨🧵</p>
        </div>

        <div className='mascot-container'>
          <img src={mascot} alt='WonderYarn Mascot' className='mascot' />
        </div>
      </div>
    </div>
  )
}

export default AboutUs
