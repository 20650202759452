import React, { createContext, useContext, useState, useCallback, useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
const WebSocketContext = createContext()
export const WebSocketProvider = ({ children }) => {
  const [loadingProgress, setLoadingProgress] = useState(0)
  const [loadingStatus, setLoadingStatus] = useState('Connecting...')
  const [isLoading, setIsLoading] = useState(false)
  const [wsConnected, setWsConnected] = useState(false)
  const [connectionError, setConnectionError] = useState(null)
  const [currentSlug, setCurrentSlug] = useState(null)
  const [sessionId, setSessionId] = useState(null) // Add session ID state
  const currentSlugRef = useRef(null)
  const [loadingComplete, setLoadingComplete] = useState(false)
  let ws
  const wsRef = useRef(null)
  const reconnectAttemptsRef = useRef(0)
  const maxReconnectAttempts = 3
  let reconnectTimeout = null
  const updateSlug = (newSlug) => {
    setCurrentSlug(newSlug)
    currentSlugRef.current = newSlug
  }
  let backendHost
  const hostname = window && window.location && window.location.hostname
  let dev = false
  if (hostname == 'o.wonderyarn.com') {
    dev = true
  }
  useEffect(() => {
    if (dev) {
      setSessionId('dev')
    } else {
      setSessionId(uuidv4())
    }
  }, [])

  const connectWebSocket = useCallback((slug) => {
    if (!sessionId) {
      console.log('ERROR')
      return // Don't connect if we don't have a session ID
    }
    if (wsRef.current && (wsRef.current.readyState === WebSocket.OPEN || wsRef.current.readyState === WebSocket.CONNECTING)) {
      if (dev) {
        console.log('WebSocket is already open or connecting. Updating subscription.')
      }
      wsRef.current.send(JSON.stringify({ type: 'subscribe', slug: currentSlugRef.current }))
      return
    }
    const connectionSlug = slug || sessionId
    setCurrentSlug(connectionSlug)
    currentSlugRef.current = connectionSlug // Sync the ref

    if (dev) {
      console.log('Attempting to connect WebSocket...')
    }
    //setIsLoading(false);
    setConnectionError(null)
    // dev
    // process.env.REACT_APP_BACKEND_HOST
    // wsRef.current = new WebSocket('ws://o.wonderyarn.com/ws');
    if (hostname == 'o.wonderyarn.com') {
      //backendHost = 'ws://o.wonderyarn.com/ws';

      backendHost = `wss://ws.wonderyarn.com/ws/${sessionId}/`
    } else {
      backendHost = `wss://ws.wonderyarn.com/ws/${sessionId}/`
    }
    wsRef.current = new WebSocket(backendHost)
    wsRef.current.onopen = () => {
      if (dev) {
        console.log('WebSocket connected')
      }
      setWsConnected(true)
      setLoadingStatus('Connected. Waiting for story processing...')
      reconnectAttemptsRef.current = 0
      wsRef.current.send(JSON.stringify({ type: 'subscribe', currentSlug, sessionId }))
    }
    wsRef.current.onmessage = (event) => {
      if (dev) {
        console.log('📩 Message from server:', event.data)
      }
      try {
        const placeSlug = currentSlugRef.current
        const data = JSON.parse(event.data)
        if (dev) {
          console.log(`Progress: ${data.progress}`)
        }
        if (data.progress == 100) {
          if (dev) {
            console.log(`Data: ${data.slug} Slug: ${currentSlug} tempid ${sessionId}`)
          }
          console.warn('data type:', data.type)
        }
        if (data.type === 'update_connection') {
          updateSlug(data.data.newSlug)
          //const newSlug = data.data.newSlug;
          //console.log(`New slug (raw): ${data.data.newSlug}`);
          //		        console.log(`Using the new slug immediately: ${newSlug}`);

          // Log the exact newSlug before updating it

          // Ensure that the currentSlug is updated properly
          //   setCurrentSlug(newSlug);
          //currentSlugRef.current = newSlug;  // Keep the ref in sync
          // After setting the new slug, log it again
          if (dev) {
            console.log(`After update: currentSlug: ${currentSlug}`)
          }
          // Update the WebSocket connection with the new slug
          // connectWebSocket(data.data.newSlug);
        } else if (data && typeof data === 'object') {
          if (data.slug === placeSlug || data.slug === sessionId) {
            if (data.type === 'story_processing' || data.type === 'image_processing') {
              const progress = Math.min(Math.max(parseInt(data.progress) || 0, 0), 100)
              if (dev) {
                console.log(`Progress update: ${progress}%`)
              }
              setLoadingProgress(progress)
              setLoadingStatus(`Processing: ${data.progress}% complete`)
              if (data.progress === 100) {
                setLoadingComplete(true)
                setIsLoading(false)
                setLoadingProgress(0)
              }
            } else {
              console.warn('Unexpected data type:', data.type)
              if (dev) {
                console.log(`Data: ${data.slug} Slug: ${placeSlug} tempid ${sessionId}`)
              }
            }
          } else {
            console.warn(`Data: ${data.slug} Slug: ${placeSlug} tempid ${sessionId}`)
          }
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error)
      }
    }

    wsRef.current.onerror = (error) => {
      console.error('WebSocket error:', error)
      setConnectionError('Unable to connect to the server. Please check your internet connection and try again.')
      setIsLoading(false)
      if (reconnectAttemptsRef.current < maxReconnectAttempts) {
        reconnectAttemptsRef.current++
        setTimeout(() => connectWebSocket(slug, sessionId), 1000 * reconnectAttemptsRef.current)
      }
    }

    wsRef.current.onclose = () => {
      if (dev) {
        console.log('WebSocket closed')
      }
      setWsConnected(false)
      if (reconnectAttemptsRef.current < maxReconnectAttempts) {
        reconnectAttemptsRef.current++
        setTimeout(() => connectWebSocket(slug, sessionId), 1000 * reconnectAttemptsRef.current)
      } else {
        setConnectionError('Unable to maintain connection. Falling back to regular updates.')
      }
    }
  }, [sessionId])
  const disconnectWebSocket = useCallback(() => {
    if (wsRef.current) {
      ws.current.close()
      ws.current = null
    }
    setWsConnected(false)
    setLoadingStatus('Idle')
    setIsLoading(false)
    setConnectionError(null)
  }, [])

  useEffect(() => {
    return () => {
      disconnectWebSocket()
    }
  }, [disconnectWebSocket])
  useEffect(() => {
    return () => {
      if (wsRef.current) {
        wsRef.current.close()
      }
    }
  }, [])
  useEffect(() => {
    currentSlugRef.current = currentSlug
  }, [currentSlug])
  return (
    <WebSocketContext.Provider
      value={{
        loadingProgress,
        loadingStatus,
        loadingComplete,
        setLoadingComplete,
        setLoadingProgress,
        isLoading,
        setIsLoading,
        wsConnected,
        connectWebSocket,
        disconnectWebSocket,
        connectionError,
        currentSlug,
        sessionId
      }}
    >
      {children}
    </WebSocketContext.Provider>
  )
}

export const useWebSocket = () => {
  const context = useContext(WebSocketContext)
  if (context === undefined) {
    throw new Error('useWebSocket must be used within a WebSocketProvider')
  }
  return context
}
