import React, { useState } from 'react'
import './ContactUs.css' // Custom CSS for Contact Us
import mascot from '../assets/wonderyarn-mascot.png' // Mascot
import { FaInstagram, FaTwitter, FaFacebook, FaTiktok } from 'react-icons/fa'
import { useTranslation } from 'react-i18next';

function ContactUs() {
  const [showForm, setShowForm] = useState(false)
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
return (
    <div className='contact-us-page'>
      <div className='stars'></div> {/* Stars background */}
      <div className='content-with-mascot'>
        <div className='content'>
          <h1>Contact Us</h1>
          <p>{t('contact-us-hello')} 🌟</p>

          <p>
            <strong>Email:</strong> <a href='mailto:contact@wonderyarn.com'>contact@wonderyarn.com</a>
          </p>
          <div className='socials'>
            <p>{t('follow')}</p>
            <div className='social-icons'>
              <div className='social-icon'>
                <a href='https://instagram.com/wonderyarnstories' target='_blank' rel='noopener noreferrer' className='instagram'>
                  <FaInstagram />
                </a>
              </div>
              <div className='social-icon'>
                <a href='https://twitter.com/wonderyarnstories' target='_blank' rel='noopener noreferrer' className='twitter'>
                  <FaTwitter />
                </a>
              </div>
              <div className='social-icon'>
                <a href='https://facebook.com/wonderyarnstories' target='_blank' rel='noopener noreferrer' className='facebook'>
                  <FaFacebook />
                </a>
              </div>
              <div className='social-icon'>
                <a href='https://tiktok.com/@wonderyarnstories' target='_blank' rel='noopener noreferrer' className='tiktok'>
                  <FaTiktok />
                </a>
              </div>
            </div>
          </div>

          <p>Stay magical, and keep spinning your tales of wonder! ✨🧵</p>
        </div>

        <div className='mascot-container'>
          <img src={mascot} alt='WonderYarn Mascot' className='mascot' />
        </div>
      </div>
    </div>
  )
}

export default ContactUs
