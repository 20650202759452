import React from 'react'
import './TermsOfService.css' // Custom CSS for Terms of Service

function TermsOfService() {
  return (
    <div className='terms-page'>
      <div className='stars'></div> {/* Stars background */}
      <div className='terms-box'>
        <h1>Terms and Conditions for WonderYarn</h1>
        <p>
          <strong>Last updated: September 25th, 2024</strong>
        </p>

        <p>
          Welcome to WonderYarn! These Terms and Conditions ("Terms") govern your access to and use of our website and services. By
          accessing or using WonderYarn, you agree to comply with and be bound by these Terms. Please read them carefully. If you do not
          agree with any part of these Terms, please do not use our services.
        </p>

        <h2>1. Acceptance of Terms</h2>
        <ul>
          <li>You are at least 18 years old.</li>
          <li>You have the legal capacity to enter into these Terms.</li>
          <li>If using WonderYarn on behalf of a minor, you are their parent or legal guardian and consent to their use of the service.</li>
        </ul>

        <h2>2. Our Service</h2>
        <p>
          WonderYarn provides an AI-powered platform that generates personalized children's bedtime stories for children aged infant to 17
          years old. Users input a child's first name, age, and select interests from a predefined list to create customized stories.
        </p>

        <h2>3. User Accounts</h2>
        <ul>
          <li>
            <strong>Registration:</strong> You may need to create an account by providing your first name, last name, and email address.
          </li>
          <li>
            <strong>Account Security:</strong> You are responsible for the confidentiality of your account credentials and for all
            activities that occur under your account.
          </li>
          <li>
            <strong>Accuracy of Information:</strong> You agree to provide accurate and complete information during registration and to
            update it as necessary.
          </li>
          <li>
            <strong>Account Termination:</strong> We reserve the right to suspend or terminate your account if we suspect any violation of
            these Terms.
          </li>
        </ul>

        <h2>4. User Conduct</h2>
        <p>You agree to use WonderYarn responsibly and not to:</p>
        <ul>
          <li>Violate any applicable laws or regulations.</li>
          <li>Infringe upon the rights of others.</li>
          <li>Introduce harmful software or disrupt the service.</li>
          <li>Misrepresent your identity or affiliation.</li>
        </ul>

        <h2>5. Content and Intellectual Property</h2>
        <p>
          <strong>User Content:</strong> You retain ownership of any content you submit, such as preferences and saved stories. By
          submitting content, you grant WonderYarn a non-exclusive, royalty-free license to use, reproduce, modify, and display the content
          solely for the purpose of providing our services.
        </p>
        <p>
          <strong>WonderYarn Content:</strong> All content provided by WonderYarn, including text, graphics, logos, and software, is the
          property of WonderYarn and protected by intellectual property laws. You may not reproduce or distribute this content without our
          written permission.
        </p>

        <h2>6. Payment and Subscriptions</h2>
        <p>
          <strong>Free and Paid Services:</strong> WonderYarn offers both free and paid subscription plans. Access to certain features may
          require payment.
        </p>
        <p>
          <strong>Payment Processing:</strong> Payments are securely processed by Stripe. By making a payment, you agree to Stripe's terms
          and conditions.
        </p>
        <p>
          <strong>Billing Information:</strong> You agree to provide current, complete, and accurate billing information.
        </p>

        <h2>7. Refunds and Cancellations</h2>
        <p>
          <strong>Refund Policy:</strong> Refunds for paid subscriptions are subject to our refund policy, which can be found on our
          website.
        </p>
        <p>
          <strong>Cancellation:</strong> You may cancel your subscription at any time through your account settings. Your access to paid
          features will continue until the end of your current billing cycle.
        </p>

        <h2>8. Privacy</h2>
        <p>
          Your use of WonderYarn is also governed by our Privacy Policy, which explains how we collect, use, and protect your personal
          information. By using our services, you consent to the practices described in the Privacy Policy.
        </p>

        <h2>9. Third-Party Services</h2>
        <p>
          WonderYarn may contain links to third-party services, including but not limited to Google Analytics, Google Ads, and Stripe. We do
          not control these third-party services and are not responsible for their content or policies. Your use of third-party services is
          at your own risk and subject to their respective terms and conditions.
        </p>

        <h2>10. Disclaimer of Warranties</h2>
        <p>
          WonderYarn is provided on an "as is" and "as available" basis. We make no warranties or representations of any kind regarding the
          service, including but not limited to:
        </p>
        <ul>
          <li>The accuracy, reliability, or completeness of any content.</li>
          <li>The availability or uninterrupted operation of the service.</li>
          <li>The absence of viruses or harmful components.</li>
        </ul>

        <h2>11. Limitation of Liability</h2>
        <p>
          To the maximum extent permitted by law, WonderYarn and its affiliates shall not be liable for any indirect, incidental, special,
          or punitive damages arising from your use of or inability to use the service.
        </p>

        <h2>12. Indemnification</h2>
        <p>
          You agree to indemnify and hold harmless WonderYarn and its officers, directors, employees, and agents from any claims or damages
          arising out of your use of the service or your violation of these Terms.
        </p>

        <h2>13. Modifications to the Service and Terms</h2>
        <ul>
          <li>
            <strong>Service Changes:</strong> We reserve the right to modify or discontinue the service at any time.
          </li>
          <li>
            <strong>Terms Updates:</strong> We may update these Terms from time to time. The updated Terms will be posted on this page with
            a new "Last updated" date.
          </li>
        </ul>

        <h2>14. Governing Law</h2>
        <p>
          These Terms shall be governed by and construed in accordance with the laws of [Insert Jurisdiction], without regard to conflict of
          law principles.
        </p>

        <h2>15. Dispute Resolution</h2>
        <p>
          Disputes shall be resolved through amicable negotiations. If negotiations fail, disputes shall be settled in the competent courts
          of [Insert Jurisdiction].
        </p>

        <h2>16. Severability</h2>
        <p>If any provision of these Terms is found to be invalid, the remaining provisions will remain in full force and effect.</p>

        <h2>17. Entire Agreement</h2>
        <p>These Terms, along with our Privacy Policy, constitute the entire agreement between you and WonderYarn.</p>

        <h2>18. Contact Information</h2>
        <p>If you have any questions, please contact us at:</p>
        <p>
          <strong>Email:</strong> contact@wonderyarn.com
        </p>
        <p>
          <strong>Address:</strong> 1391 NW Saint Lucie West Blvd #206 Port St Lucie, FL 34986
        </p>
      </div>
    </div>
  )
}

export default TermsOfService
