import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

function InputForm() {
  const [name, setName] = useState('')
  const [age, setAge] = useState('')
  const [interests, setInterests] = useState('')
  const navigate = useNavigate()

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const response = await axios.post('https://wonderyarn.com/api/generate-story', {
        name,
        age,
        interests
      })
      // Pass the story to the story display page
      navigate('/story', { state: { story: response.data.story } })
    } catch (error) {
      console.error('Error generating story:', error)
    }
  }

  return (
    <div className='input-form'>
      <h2>Personalize Your Story</h2>
      <form onSubmit={handleSubmit}>
        <label>Child's Name:</label>
        <input type='text' value={name} onChange={(e) => setName(e.target.value)} required />

        <label>Child's Age:</label>
        <input type='number' value={age} onChange={(e) => setAge(e.target.value)} required />

        <label>Child's Interests:</label>
        <input type='text' value={interests} onChange={(e) => setInterests(e.target.value)} required />

        <button type='submit'>Generate Story</button>
      </form>
    </div>
  )
}

export default InputForm
