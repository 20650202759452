import React, { useEffect } from 'react'
import './ContactUs.css'; // Reuse your existing styles
import mascot from '../assets/wonderyarn-mascot.png'; // Mascot


function Error() {
  useEffect(() => {
    document.title = "WonderYarn - Oops!"; // Set the title for the 404 page
  }, []); // Empty dependency array ensures this runs once when the component is mounted
  return (
    <div className="contact-us-page">
      <div className="stars"></div> {/* Background with stars */}
      <div className="content-with-mascot">
        <div className="content">
          <h1>Oops!</h1>
          <p>It seems like you've wandered off into a different galaxy! 🌌</p>
          <p>
            But don’t worry, you can always find your way back to the magic of <a href="/">WonderYarn</a>!
          </p>
          <p>
            If you're still lost, feel free to <a href="mailto:contact@wonderyarn.com">contact us</a> and we'll help you find your way.
          </p>
        </div>

        <div className="mascot-container">
          <img src={mascot} alt="WonderYarn Mascot" className="mascot" />
        </div>
      </div>
    </div>
  );
}

export default Error;
