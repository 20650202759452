import React from 'react'
import './PrivacyPolicy.css' // Custom CSS for Privacy Policy page
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShieldAlt, faCookieBite, faUserShield, faUserSecret, faCreditCard } from '@fortawesome/free-solid-svg-icons'

function PrivacyPolicy() {
  return (
    <div className='privacy-policy-page'>
      <div className='stars'></div> {/* Stars background */}
      <div className='content-with-policy'>
        <div className='content'>
          <h1>Privacy Policy</h1>
          <p>Last updated: September 23, 2024</p>

          <div className='policy-section'>
            <div className='icon-text'>
              <FontAwesomeIcon icon={faUserShield} className='policy-icon' />
              <h2>Information We Collect</h2>
            </div>
            <p>
              <strong>Personal Information:</strong> We collect the following personal information to provide a personalized experience and
              offer our services:
            </p>
            <ul>
              <li>
                <strong>Child's first name and age:</strong> Used for creating personalized stories.
              </li>
              <li>
                <strong>Parent's email address:</strong> Used to register accounts, send updates, and communication.
              </li>
              <li>
                <strong>Interests:</strong> Selected by you to tailor the story's content to your child’s preferences.
              </li>
              <li>
                <strong>Usage Data:</strong> Automatically collected to track how the site is used, including pages visited, time spent, and
                actions taken.
              </li>
            </ul>
          </div>

          <div className='policy-section'>
            <div className='icon-text'>
              <FontAwesomeIcon icon={faCookieBite} className='policy-icon' />
              <h2>Cookies and Tracking Technologies</h2>
            </div>
            <p>
              We use cookies and similar technologies to provide, maintain, and improve our services. Cookies help us remember your
              preferences and understand how you interact with WonderYarn.
            </p>
            <ul>
              <li>
                <strong>Essential Cookies:</strong> Required for the website to function properly, such as maintaining session information.
              </li>
              <li>
                <strong>Analytics Cookies:</strong> Used to track site usage and improve user experience (powered by Google Analytics).
              </li>
              <li>
                <strong>Advertising Cookies:</strong> Used to serve personalized ads through Google Ads.
              </li>
            </ul>
          </div>

          <div className='policy-section'>
            <div className='icon-text'>
              <FontAwesomeIcon icon={faShieldAlt} className='policy-icon' />
              <h2>How We Use Your Information</h2>
            </div>
            <p>We use your personal information for the following purposes:</p>
            <ul>
              <li>
                <strong>Personalization:</strong> We create personalized stories based on your child's name, age, and interests.
              </li>
              <li>
                <strong>Account Creation and Management:</strong> We use your email to register your account and manage any preferences or
                saved stories.
              </li>
              <li>
                <strong>Analytics:</strong> We analyze site usage to improve performance and enhance user experience through Google
                Analytics.
              </li>
              <li>
                <strong>Advertising:</strong> We may use Google Ads to serve you relevant ads based on your preferences and interactions
                with our website.
              </li>
            </ul>
          </div>

          <div className='policy-section'>
            <div className='icon-text'>
              <FontAwesomeIcon icon={faCreditCard} className='policy-icon' />
              <h2>Third-Party Services We Use</h2>
            </div>
            <p>
              We rely on trusted third-party services to operate WonderYarn and process your data. Here’s how they handle your information:
            </p>
            <ul>
              <li>
                <strong>Google Analytics:</strong> Tracks usage data via cookies to provide insights into how you interact with the site.
                More about their privacy practices can be found on their{' '}
                <a href='https://policies.google.com/privacy' target='_blank' rel='noreferrer'>
                  privacy policy page
                </a>
                .
              </li>
              <li>
                <strong>Google Fonts:</strong> We use Google Fonts to ensure the correct display of fonts. These may collect usage data as
                described in Google's{' '}
                <a href='https://policies.google.com/privacy' target='_blank' rel='noreferrer'>
                  privacy policy
                </a>
                .
              </li>
              <li>
                <strong>Stripe:</strong> Handles all payment processing securely. Stripe collects personal and payment data such as credit
                card details to facilitate transactions. For more, visit the{' '}
                <a href='https://stripe.com/privacy' target='_blank' rel='noreferrer'>
                  Stripe privacy policy
                </a>
                .
              </li>
              <li>
                <strong>Google Ads:</strong> We display personalized ads using Google Ads, which may track interactions for targeting. Learn
                more about Google's ad policy on their{' '}
                <a href='https://policies.google.com/technologies/ads' target='_blank' rel='noreferrer'>
                  ad policies page
                </a>
                .
              </li>
            </ul>
          </div>

          <div className='policy-section'>
            <div className='icon-text'>
              <FontAwesomeIcon icon={faUserSecret} className='policy-icon' />
              <h2>Children's Privacy</h2>
            </div>
            <p>WonderYarn is committed to protecting the privacy of children:</p>
            <ul>
              <li>
                <strong>Parental Consent:</strong> We require that only parents or guardians register for accounts and provide information
                for their children.
              </li>
              <li>
                <strong>Minimal Data Collection:</strong> We collect the minimal amount of personal data necessary to personalize stories.
              </li>
              <li>
                <strong>Compliance:</strong> We comply with the Children’s Online Privacy Protection Act (COPPA) and other applicable
                privacy laws.
              </li>
            </ul>
          </div>

          <div className='policy-section'>
            <h2>Data Security</h2>
            <p>
              We take reasonable measures to protect your data. However, no method of transmission over the internet is completely secure.
              While we strive to protect your personal information, we cannot guarantee its absolute security.
            </p>
          </div>

          <div className='policy-section'>
            <h2>Your Rights</h2>
            <ul>
              <li>
                <strong>Access:</strong> You can access the information we hold about you by logging into your account.
              </li>
              <li>
                <strong>Opt-Out:</strong> You can opt out of promotional emails at any time by clicking "unsubscribe" in the email.
              </li>
              <li>
                <strong>Data Deletion:</strong> You may request the deletion of your personal data by contacting us at
                privacy@wonderyarn.com.
              </li>
            </ul>
          </div>

          <div className='policy-section'>
            <h2>Changes to This Privacy Policy</h2>
            <p>
              We may update this policy from time to time. If changes are made, we will update the "Last Updated" date at the top of this
              policy.
            </p>
          </div>

          <div className='policy-section'>
            <h2>Contact Us</h2>
            <p>If you have any questions or concerns about this Privacy Policy, feel free to contact us at:</p>
            <p>
              <strong>Email:</strong> privacy@wonderyarn.com
            </p>
            <p>
              <strong>Address:</strong> 1391 NW Saint Lucie West Blvd #206 Port St Lucie, FL 34986
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
