import ReactGA from 'react-ga4';

// Initialize Google Analytics 4
export const initGA4 = () => {
  ReactGA.initialize('G-6Q0J0LQRET');  // Replace with your GA4 Measurement ID
};

// Track page views
export const logPageView = () => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
};

